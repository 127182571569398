import React from 'react'
import QRCode from 'qrcode.react'

const CustomQRCode = ({ serviceCode, email, applicationId }) => {

  // Construct the data string
  let data = `https://daiu.app.link/yBE7efy4PI?service_code=${serviceCode}`
  if (email) {
    data += `&email=${email}`
  }
  if (applicationId) {
    data += `&applicationId=${applicationId}`
  }

  return (
      <div style={{ paddingTop: 30 }}>
        {/* Render the QRCode component with the data */}
        <QRCode value={data} size={150} />
      </div>
  )
}

export default CustomQRCode

