import React from 'react'

import Links from './Links'
import './Main.css'

const Main = (props) => {

  const mainStringEnglish = `Use your phone to scan the QR code below to be taken to ${props.companyName}'s MyVerify App to complete your validation.`
  const mainStringChinese = `请使用您的手机扫描下方二维码，您将下载${props.companyName}的MyVerify App完成您的验证.`
    const mainStringSpanish = `Utilice su teléfono para escanear el código QR a continuación para acceder a la aplicación MyVerify de ${props.companyName} y completar su validación.`

  return (
      <div className={'main-container'}>
        <img className="logo" alt={''} src={props.logo}/>
        <div className={'main-text'}>{mainStringEnglish}<br/><br/>{mainStringChinese}<br/><br/>{mainStringSpanish}</div>
        <Links serviceCode={props.serviceCode} email={props.email} applicationId={props.applicationId}/>
      </div>
  )

}

export default Main
