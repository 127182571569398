import React from 'react'
import reactCSS from 'reactcss'

import QRCode from './links/QRCode'

const Links = (props) => {
  const styles = reactCSS({
    'default': {
      container: {
        justifyContent: 'center',
        display: 'flex',
      },
      storeHolder: {
        display: 'flex',
        flexDirection: 'row',
      },
    },
  })

  return (
    <div style={styles.container}>
      <QRCode serviceCode={props.serviceCode} email={props.email} applicationId={props.applicationId}/>
    </div>
  )
}

export default Links
