import React, { Component } from 'react'
import reactCSS from 'reactcss'
import axios from 'axios'
import Main from './components/Main'
import './App.css'

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const getUrlParams = (search) => {
  let hashes = search.slice(search.indexOf('?') + 1).split('&')
  let params = {}
  hashes.forEach(hash => {
    let [key, val] = hash.split('=')
    params[key] = decodeURIComponent(val)
  })

  return params
}

class App extends Component {

  constructor(props) {

    super(props)
    const params = getUrlParams(window.location.search)
    let serviceCode
    let email
    let applicationId
    if (params['service_code']) {
      serviceCode = params['service_code']
    }
    if (params['email']) {
      email = params['email']
    }
    if (params['applicationId']) {
      applicationId = params['applicationId']
    }
    this.cacheBust = Math.random()
    this.state = {
      serviceCode: serviceCode,
      email: email,
      applicationId: applicationId,
      countryCode: '+1',
      phoneNumber: '',
      adBlocker: window.adBlocker,
      loading: true,
      introDone: false,
      logoLoaded: false,
      logo_light: '',
      dataError: false,
      name: ''
    }

    window.adBlock = (block) => {
      this.setState({
        adBlocker: true
      })
    }
  }

  componentDidMount() {
    axios.get(API_BASE_URL +'/api/business-logos/' + this.state.serviceCode + "/")
      .then((response) => {
        this.setState({
          logo_light: response.data.logo_light,
          logo_dark: response.data.logo_dark,
          name: response.data.name,
          loading: false
        })
      })
      .catch((error) => {
        const {logo_dark, code, logo_light, name} = {"code":"ntknzu","name":"Netkidemo","logo_light":"https://netkipearl-eu.s3.amazonaws.com/businesses/assets/netki250x250.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYZACD5PZCVC4FWVT%2F20220415%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20220415T143734Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=82426838f314fa2904ab728b7f15f641603e3bb01e3841742897526aac3feb7c","logo_dark":"https://netkipearl-eu.s3.amazonaws.com/businesses/assets/netki250x250.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYZACD5PZCVC4FWVT%2F20220415%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20220415T143734Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=82426838f314fa2904ab728b7f15f641603e3bb01e3841742897526aac3feb7c"}
        this.setState({
          logo_light: logo_light,
          logo_dark: logo_dark,
          name: name,
          loading: false
        })
        // this.setState({
        //   dataError: error,
        //   logoLoaded: true
        // })
      })
    setTimeout(this.introDone, 4000)
    window.gtag('event', 'app_loaded', { 'event_label': 'service_code:' + this.state.serviceCode })
  }

  introDone = () => {
    this.setState({
      introDone: true
    })
  }

  phoneNumberInput = (event) => {
    if ('0123456789'.indexOf(event.target.value.slice(-1)) !== -1) {
      this.setState({
        phoneNumber: event.target.value,
      })
    }
  }

  countryCodeInput = (event) => this.setState({ countryCode: '+' + event.target.value })

  submitSMS = () => {
    window.gtag('event', 'submit_sms_sent', { 'event_label': 'service_code:' + this.state.serviceCode })
    window.sendSMS(this.state.countryCode + this.state.phoneNumber, this.smsCallback, this.state.serviceCode)
  }

  smsCallback = (error, result) => {
    if (error) {
      window.gtag('event', 'submit_sms_error', { 'event_label': 'error: ' + error.toString() + ' serice_code:' + this.state.serviceCode })
      if (error.toString().includes('429')) {
        alert("Sorry, you've reached your limit with this phone number.  Please try again in an hour.")
      } else {
        alert("Sorry, something went wrong.")
      }
    }
    else {
      window.gtag('event', 'submit_sms_success', { 'event_label': 'service_code:' + this.state.serviceCode })
      alert("SMS sent!")
    }
  }

  countrySelect = (code) => {
    this.setState({
      countryCode: '+' + code
    })
  }

  logoLoaded = () => {
    this.setState({
      logoLoaded: true
    })
  }

  render() {
    const styles = reactCSS({
      'default': {
        container: {
          width: '100%',
        },
        errorOverlay: {
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
          backgroundColor: 'rgba(55, 55, 55, 0.9)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        },
        errorMsg: {
          color: 'white',
          padding: 20,
          fontSize: 28,
          width: 500,
          height: 300,
          display: 'flex',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.3)',
          borderStyle: 'solid',
          borderWidth: 2,
          borderColor: 'rgba(255, 255, 255, 0.5)',
          borderRadius: 20,
        }
      },
    })

    if (this.state.dataError && this.state.introDone) {
      return (<div style={styles.errorOverlay}>
        <div style={styles.errorMsg}>
          This is not a valid URL.  Please contact your vendor.
                  </div>
      </div>)
    }
    if (this.state.loading || !this.state.introDone || !this.state.logoLoaded) {
      return (<div className={'loader'} style={{ backgroundColor: 'white', textAlign: 'center', height: '100%' }}>
        <img className={'cacheBuster'} alt="" src={"./intro.gif?" + this.cacheBust} width="348" height="764" />
        <img alt="" style={{ visibility: 'hidden', position: 'absolute' }} onLoad={this.logoLoaded} src={this.state.logo_light} />
      </div>)
    }


    return (
      <div className={'app-container'} style={styles.container}>
        <Main phoneNumber={this.state.phoneNumber}
          phoneNumberInput={this.phoneNumberInput}
          countryCode={this.state.countryCode}
          countryCodeInput={this.countryCodeInput}
          submitSMS={this.submitSMS}
          serviceCode={this.state.serviceCode}
          email={this.state.email}
          applicationId={this.state.applicationId}
          countrySelect={this.countrySelect}
          logo={this.state.logo_dark}
          logoLoaded={this.logoLoaded}
          companyName={this.state.name} />

        {this.state.adBlocker &&
          <div style={styles.errorOverlay}>
            <div style={styles.errorMsg}>
              Please turn off Ad Blocking software in your Browser and refresh the page.
                  </div>
          </div>
        }
      </div>
    )
  }
}

export default App
